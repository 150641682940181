import * as moment from 'moment-timezone';
import 'chosen-js/chosen.jquery';
import {guid, url, deleteFilterLine} from "../utils";
import * as Solr from './Solr';

const FILTER_BUTTONS_TEXT = {
    search: {
        singular:   '',
        plural:     '',
    },
    categories: {
        singular:   'Category',
        plural:     'Categories',
    },
    manufacturer_ids: {
        singular:   'Manufacturer',
        plural:     'Manufacturers',
    },
    brand_ids: {
        singular:   'Brand',
        plural:     'Brands',
    },
    model_ids: {
        singular:   'Series',
        plural:     'Series',
    },
    type_ids: {
        singular:   'Model',
        plural:     'Models',
    },
    condition_ids: {
        singular:   'Condition',
        plural:     'Conditions',
    },
    countries: {
        singular:   'Geography',
        plural:     'Regions',
    },
    country_codes: {
        singular:   'Country',
        plural:     'Countries',
    },
    company_id: {
        singular:   'Company',
        plural:     'Companies',
    },
    user_id: {
        singular:   'User',
        plural:     'Users',
    },
};

const CORE = {
    listing:    'listing',
    part:       'part',
};

export const FilterDrawer_v2 = (function() {
    const COLLAPSED_CLASS = 'collapse';
    const FILTER_BREAKPOINT = 992;

    const exports = {};

    exports.TOP_OFFSET = 10;

    exports.data = {
        core: null, // "part", "listing"
        mode: null, // "profile"
        isMemberViewing: false, // todo: make it work
    };

    exports.$filterElement = '';
    exports.$filterButton = '';
    exports.filterCount = 0;
    exports.saved_searches = {};
    exports.distanceToSearchInput = 0;
    exports.isButtonSticky = false;
    exports.isMobile = false;
    exports.isMemberViewing = false;
    exports.geographies = [];

    exports.init = function(options, callback) {
        if ($(".filter-drawer").length === 0) {
            return;
        }

        // console.debug('filter drawer v2 init');

        _data.rewrite_url = true;
        _data.update_url = true;

        exports.data = $.extend(exports.data, options);

        exports.$filterElement = $(".filter-drawer");
        exports.$filterButton = $('#lft_bar_filter');
        exports.isMobile = framework.screen().width < FILTER_BREAKPOINT;
        exports.isMemberViewing = _data._show_member_listings;

        exports.populateSavedSearches(false);

        exports.populateFilters(_data.filters);

        if (exports.$filterElement.hasClass(COLLAPSED_CLASS)) {
            $("#lft_bar_filter .tl-navigate-right").removeClass("active");
            $("#lft_bar .tl-navigate-right").removeClass("active");
            $(".tl-filt").removeClass("tl-backdim");
        } else {
            $("#lft_bar_filter .tl-navigate-right").addClass("active");
            $("#lft_bar .tl-navigate-right").addClass("active");
            $(".tl-filt").addClass("tl-backdim");
        }

        if (!_data.limit_to_data) {
            $('#manu-select')
                .on('chosen:showing_dropdown', function() {
                    const manuDropOptions = $('#manu-select option').length;
                    let ajax_GetManuItemsDrop;

                    if (manuDropOptions === 0) {
                        const $select = $('#manu-select');
                        $select.html('<option>Loading Please Wait...</option>');
                        $select.trigger("chosen:updated");

                        if (typeof ajax_GetManuItemsDrop !== 'undefined') {
                            ajax_GetManuItemsDrop.abort();
                        }

                        var manu_name_data = {
                            "tlpxtoken": window.tlpxtoken,
                            "x-sender": "js"
                        };

                        ajax_GetManuItemsDrop = framework.get(url("api/all_manus"), manu_name_data, function(r) {
                            if (r.manufacturers) {
                                $select.html('<option>more...</option>');

                                r.manufacturers.forEach(function(manu) {
                                    $select.append(`<option value="${manu.PK_ID}">${manu.MFGDESC}</option>`);
                                });

                                $select.trigger("chosen:updated");
                            }
                        });
                    }
                })
                .on("change", function() {
                    const id = $('#manu-select').val();
                    const name = $("#manu-select option:selected").text();

                    if (id === "more..." || id === "") {
                        return;
                    }

                    if (!_data.filters.hasOwnProperty('manufacturer_ids')) {
                        _data.filters.manufacturer_ids = [];
                    }

                    if (_data.filters.manufacturer_ids.indexOf(id) === -1) {
                        _data.filters.manufacturer_ids.push(id);
                    }

                    if (_data.filters.manufacturer_ids.length === 1) {
                        _data.filters.manufacturer_name = name;
                    } else {
                        _data.filters.manufacturer_name = '';
                    }

                    exports.refresh();
                })
                .chosen({width: "90%"});
        }

        exports.__initFilterButtons();
        exports.__initFilterCheckboxesListeners();

        $(window)
            .on('resize', function() {
                exports.isMobile = framework.screen().width < FILTER_BREAKPOINT;
                exports.$filterElement.toggleClass(COLLAPSED_CLASS, exports.isMobile);
                exports.__updateFilterElements();
            })
            .on('resize scroll', function() {
                if (exports.isMobile) {
                    exports.distanceToSearchInput = $('#search_input').offset().top - $(window).scrollTop();

                    if (exports.distanceToSearchInput < exports.TOP_OFFSET && !exports.isButtonSticky) {
                        exports.$filterButton.css('transform', 'translateY(0)');
                        exports.isButtonSticky = true;
                    } else if (exports.distanceToSearchInput >= exports.TOP_OFFSET) {
                        exports.$filterButton.css('transform', 'translateY(' + (exports.distanceToSearchInput - exports.TOP_OFFSET) + 'px)');
                        exports.isButtonSticky = false;
                    }
                } else {
                    exports.$filterButton.removeAttr('style');
                }
            })
            .trigger('resize');

        document.addEventListener('filter.populateFilters', function(event) {
            exports.populateFilters(event.detail.data);
        });

        document.addEventListener('filter.populateSavedSearches', function(event) {
            exports.populateSavedSearches(event.detail.sd, event.detail.k);
        });

        document.addEventListener('filter.getSearches', function() {
            exports.getSearches();
        });

        document.addEventListener('filter.refresh', function() {
            exports.refresh();
        });

        document.addEventListener('filter.changeCat1', function(event) {
            exports.chooseCat1(event.detail.id, event.detail.text);
        });

        document.addEventListener('filter.changeManu', function(event) {

            if (!_data.filters.hasOwnProperty('manufacturer_ids')) {
                _data.filters.manufacturer_ids = [];
            }

            if (_data.filters.manufacturer_ids.indexOf(event.detail.id) === -1) {
                _data.filters.manufacturer_ids.push(event.detail.id);
            } else {
                _data.filters.manufacturer_ids = _data.filters.manufacturer_ids.filter(id => id !== event.detail.id);
            }

        });

        exports.$filterElement.css('display', 'block');
        $('.main-tabs').removeClass('main-tabs--loading');

        if (typeof callback === "function") {
            callback();
        }
    };

    exports.__initFilterButtons = function() {
        $(document)
            .on('click', '[data-filter-remove]', function() {
                const field = this.getAttribute('data-filter-remove');

                // console.debug('removing', field);

                if (field === 'k') {
                    $('.tl-search__clear').trigger('click');
                    return;
                }

                exports.__removeFilters(field);

                if (field === 'categories') {
                    document.dispatchEvent(new CustomEvent('filter.cat1.changed', {}));
                    exports.__removeFilters(['manufacturer_ids','brand_ids','model_ids','type_ids']);
                } else if (field === 'manufacturer_ids') {
                    exports.__removeFilters(['brand_ids','model_ids','type_ids']);
                    exports.clearManufacturersDropdown();
                } else if (field === 'brand_ids') {
                    exports.__removeFilters(['model_ids','type_ids']);
                } else if (field === 'model_ids') {
                    exports.__removeFilters(['type_ids']);
                } else if (field === 'country_codes') {
                    exports.__removeFilters(['company_id']);
                } else if (field === 'countries') {
                    exports.__removeFilters(['country_codes', 'company_id']);
                }
            });


        $('.created-filter').click(function() {
            if ($(this).is(':checked')) {
                $('.replied-filter').prop('checked', false);

                delete _data.filters.replied_listings;

                _data.filters.created_by = user.USERID;
            } else {
                delete _data.filters.created_by;
            }

            exports.refresh();
        });

        $('.replied-filter').click(function() {
            if ($(this).is(':checked')) {
                $('.created-filter').prop('checked', false);

                delete _data.filters.created_by;

                if (typeof replied !== 'undefined') {
                    _data.filters.replied_listings = Object.keys(replied);
                } else {
                    _data.filters.replied_listings = [];
                }
            } else {
                delete _data.filters.replied_listings;
            }

            exports.refresh();
        });


        $('.message-type-filter').click(function() {
            _data.remove_saved_search();

            const isWtb = $('.wtb-filter').is(':checked');
            const isWts = $('.wts-filter').is(':checked');
            const isBulkLots = $('.target-bulk-filter').is(':checked');
            const isIndividualItems = $('.target-indiv-filter').is(':checked');

            if (isWtb && !isWts) {
                _data.filters.type = 1;
                delete _data.filters.target_type;
            } else if (isWts && !isWtb) {
                _data.filters.type = 2;
            } else {
                delete _data.filters.type;
            }

            if (isBulkLots && !isIndividualItems || !isBulkLots && isIndividualItems) {
                if (isWtb) {
                    delete _data.filters.type;
                } else {
                    _data.filters.type = 2;
                }
            }

            if (isBulkLots && !isIndividualItems) {
                _data.filters.target_type = 1;
            } else if (isIndividualItems && !isBulkLots) {
                _data.filters.target_type = 2;
            } else {
                delete _data.filters.target_type;
            }

            exports.refresh();
        });

        $('.show-condition-legend').click(function() {
            if (_data.filters.hasOwnProperty('categories') && _data.filters.categories) {
                tradeloop.modal('condition_legend', null, {
                    category_1_id: _data.filters.categories[0].id
                });
            }
        });

        $('#btn-saved-searches').click(function() {
            if (is_logged_in) {

                if (exports.filterCount === 0 && (!_data.filters.hasOwnProperty('search') || _data.filters.search === "")) {
                    tradeloop.modal('message', function() {
                        $('.modal-title').html('Save Search');
                        $('.modal-subtitle').html('');
                        $('.modal-message').html('<p>Filter the page first.</p><p>Or <a href="' + tradeloop.emailSubscriptions() + '">subscribe to the daily digest</a> of top listings.</p>');
                        $('.btn-text').html('Got It');
                    });
                } else if (_data.filters.hasOwnProperty('selected_search')) {
                    tradeloop.modal('message', function() {
                        $('.modal-title').html('Save Search');
                        $('.modal-subtitle').html('');
                        $('.modal-message').html('<p>You already receive an alert for this search.</p><p>Change your filters to create a new search.</p>');
                        $('.btn-text').html('Got It');
                    });
                } else {

                    if (exports.isMemberViewing) {
                        window.location = tradeloop.cfMembersSite + '/broadcast/settings/alerts/';
                    } else {
                        tradeloop.modal('save_this_search');
                        framework.post(url('api/log_event'), {
                            _token: $('input[name="_token"]:first').val(),
                            'type_id': 111
                        });
                    }

                }

            } else {
                tradeloop.modal('register', null, {type: 'search'}, 'create-alert');
            }
        });

        $('.btn-reset-filters').click(function() {

            _data.filters = {
                page: 1
            };

            $('.tl-search__input').val('');
            $('[data-filter-button]').addClass('hidden');

            exports.clearManufacturersDropdown();

            history.pushState({}, "Tradeloop | The Wholesale Marketplace for Used Electronics", window.location.href.split('?')[0]);

            _data.refresh(function() {
                exports.populateFilters(_data.filters);
                exports.populateSavedSearches(false, true);
                document.dispatchEvent(new CustomEvent('listings.get'));
            });
        });


        $('#lft_bar, #lft_bar_filter').click(function() {
            exports.$filterElement.toggleClass("collapse");
            exports.__updateFilterElements();
        });

        $('.tl-filt').click(function() {
            exports.$filterElement.addClass("collapse");
            exports.__updateFilterElements();
        });
    };

    exports.__initFilterCheckboxesListeners = function() {
        $(document)
            .on('click', '.cat-1-filter', function(event) {
                const $me = $(event.target);
                const cat_name = $me.attr('data-text');

                _data.remove_saved_search();

                if ($me.is(':checked')) {
                    const id = $me.data('id');

                    exports.chooseCat1(id, cat_name);
                } else {
                    delete _data.filters.categories;
                    exports.__removeFilters(['manufacturer_ids','brand_ids','model_ids','type_ids']);

                    document.dispatchEvent(new CustomEvent('filter.cat1.changed', {}));
                }

                // update filter buttons
                exports.__updateFilterButton('categories');

                exports.refresh();
            })
            .on('click', '.cat-2-filter', function(event) {
                const $me = $(event.target);
                const id = $me.data('id');
                const cat1id = $me.data('cat1id');

                _data.remove_saved_search();

                _data.filters.categories.forEach(function(cat1) {
                    if (cat1.id === cat1id) {
                        const cat2 = cat1.sub_categories.find(cat2 => cat2.id === id);

                        if ($me.is(':checked') && typeof cat2 === 'undefined') {
                            cat1.sub_categories.push({
                                "id": id,
                                "type": "cat_2_id",
                                "sub_categories": []
                            });
                        }

                        if (!$me.is(':checked') && typeof cat2 !== 'undefined') {
                            const cat2id = cat1.sub_categories.findIndex(cat2 => cat2.id === id);
                            cat1.sub_categories.splice(cat2id, 1);
                        }
                    }
                });

                exports.refresh();
            })
            .on('click', '.cat-3-filter', function(event) {
                const $me = $(event.target);
                const id = $me.data('id');
                const isChecked = $me.is(':checked');
                const cat1id = $me.data('cat1id');
                const cat2id = $me.data('cat2id');

                _data.remove_saved_search();

                _data.filters.categories.forEach(function(cat1) {
                    if (cat1.id === parseInt(cat1id)) {
                        let cat2found = false;

                        cat1.sub_categories.forEach(function(cat2, i) {
                            if (cat2.id === parseInt(cat2id)) {
                                cat2found = true;

                                const cat3 = cat2.sub_categories.find(cat3 => cat3.id === parseInt(id));

                                if (typeof cat3 === "undefined" && isChecked) {
                                    cat1.sub_categories[i].sub_categories.push({
                                        "id": id,
                                        "type": "cat_3_id"
                                    });
                                }

                                if (typeof cat3 !== "undefined" && !isChecked) {
                                    const cat3id = cat2.sub_categories.indexOf(cat3);
                                    cat2.sub_categories.splice(cat3id, 1);
                                }
                            }
                        });

                        if (!cat2found && isChecked) {
                            cat1.sub_categories.push({
                                "id": cat2id,
                                "type": "cat_2_id",
                                "sub_categories": [{
                                    "id": id,
                                    "type": "cat_3_id"
                                }]
                            });
                        }
                    }
                });

                exports.refresh();
            })
            .on('click', '[data-filter-toggle]', function() {
                const field = this.getAttribute('data-filter-toggle');
                const text = this.getAttribute('data-name');
                let value = this.getAttribute('data-id');

                if (!field) {
                    return;
                }

                // todo: check if we should uncheck saved searches for all fields
                _data.remove_saved_search();

                if (!isNaN(parseInt(value))) {
                    value = parseInt(value);
                }

                // update filters string
                if (this.checked) {
                    if (!_data.filters.hasOwnProperty(field)) {
                        _data.filters[field] = [];
                    }
                    if (_data.filters[field].indexOf(value) === -1) {
                        _data.filters[field].push(value);
                    }
                } else {
                    if (_data.filters.hasOwnProperty(field)) {
                        if (_data.filters[field].length > 1) {
                            _data.filters[field].remove(value);
                        } else {
                            delete _data.filters[field];
                        }

                        // clean linked filters
                        // replicated from [data-filter-remove] click handler
                        if (field === 'manufacturer_ids') {
                            delete _data.filters.brand_ids;
                            delete _data.filters.model_ids;
                            delete _data.filters.type_ids;
                        } else if (field === 'brand_ids') {
                            delete _data.filters.model_ids;
                            delete _data.filters.type_ids;
                        } else if (field === 'model_ids') {
                            delete _data.filters.type_ids;
                        } else if (field === 'country_codes') {
                            delete _data.filters.company_id;
                        } else if (field === 'countries') {
                            // delete countries, associated with region
                            if (exports.geographies.hasOwnProperty(value) && _data.filters.hasOwnProperty('country_codes')) {
                                exports.geographies[value].forEach(country => {
                                    if (_data.filters.country_codes.indexOf(country) !== -1) {
                                        _data.filters.country_codes.splice(_data.filters.country_codes.indexOf(country), 1);
                                    }
                                });
                            }
                        }
                    }
                }

                // update filter buttons
                exports.__updateFilterButton(field);

                // redraw filter bar
                exports.refresh();
            })
            .on('click', '[name="show_results"]', function() {
                const showMembersOnly = $('[name="show_results"][value="members_only"]')[0].checked;
                const showPublicOnly = $('[name="show_results"][value="public_only"]')[0].checked;

                if (showMembersOnly && !showPublicOnly) {
                    _data.filters['displays_to_non_members'] = 0;
                } else if (!showMembersOnly && showPublicOnly) {
                    _data.filters['displays_to_non_members'] = 1;
                } else {
                    delete _data.filters['displays_to_non_members'];
                }

                exports.refresh();
            });
    };

    exports.__removeFilters = function(filters, forceReFilterRefresh = true, callback) {
        _data.remove_saved_search();

        if (filters && filters.length > 0) {
            if (Array.isArray(filters)) {
                filters.forEach(filter => {
                    if (_data.filters.hasOwnProperty(filter)) {
                        delete _data.filters[filter];
                    }
                });
            } else {
                if (_data.filters.hasOwnProperty(filters)) {
                    delete _data.filters[filters];
                }
            }
        }

        if (callback && typeof callback === "function") {
            callback();
        }

        if (forceReFilterRefresh) {
            exports.refresh();
        }
    };

    exports.__updateFilterElements = function() {
        if (exports.$filterElement.hasClass(COLLAPSED_CLASS)) {
            $("#lft_bar_filter .tl-navigate-right").removeClass("active");
            $("#lft_bar .tl-navigate-right").addClass("hidden");
            $(".tl-filt").removeClass("tl-backdim");
        } else {
            $("#lft_bar_filter .tl-navigate-right").addClass("active");
            $("#lft_bar .tl-navigate-right").removeClass("hidden").addClass("active");
            $(".tl-filt").addClass("tl-backdim");
        }

        if (exports.isMobile) {
            $('#header_bottom_row_wrapper').css('padding-left', '20px');
        } else {
            $('#header_bottom_row_wrapper').removeAttr('style');
        }
    };

    exports.__updateFilterButton = function(fieldName) {
        const $button = $('[data-filter-button="' + fieldName + '"]');

        if (!_data.filters.hasOwnProperty(fieldName) || _data.filters[fieldName].length < 1) {
            $button.addClass('hidden');
        } else {
            let buttonText = '';
            let filterValue = '';

            if (fieldName === 'search') {
                buttonText = '"' + _data.filters.search + '"';
            } else if (fieldName === 'categories') {
                filterValue = _data.filters.categories[0].id;
                buttonText = $('input[type="checkbox"][data-id="' + filterValue + '"]').attr('data-text');

                if (buttonText === undefined) {
                    buttonText = window.category_tree.hasOwnProperty(filterValue)
                        ? window.category_tree[filterValue].title
                        : 'Category';
                }
            } else if (Array.isArray(_data.filters[fieldName]) && _data.filters[fieldName].length > 1) {
                buttonText = _data.filters[fieldName].length + ' ' + FILTER_BUTTONS_TEXT[fieldName]['plural'];
            } else {
                filterValue = Array.isArray(_data.filters[fieldName])
                        ? _data.filters[fieldName][0]
                        : _data.filters[fieldName];

                if (fieldName === 'manufacturer_ids' && $('.manu-plate[data-id="' + filterValue + '"]').attr('data-text') !== undefined) {
                    buttonText = $('.manu-plate[data-id="' + filterValue + '"]').attr('data-text');
                } else {
                    buttonText = $('input[type="checkbox"][data-id="' + filterValue + '"]').attr('data-name');
                }
            }

            $button.find('.selected-search-inner').text(buttonText);
            $button.removeClass('hidden');
        }

    };


    exports.refresh = function() {
        _data.refresh(function() {
            exports.populateFilters(_data.filters);
            document.dispatchEvent(new CustomEvent('listings.get'));
        });
    };


    exports.updateFilterCount = function(number) {
        exports.filterCount += number;
        $('.filter-badge').removeClass('hidden').html(exports.filterCount);
        $('#filters_clear_count').text(' (' + exports.filterCount + ')');
        $('.btn-reset-filters').removeClass('hidden');
        $('#btn-saved-searches').removeClass('hidden');
    };

    exports.resetFilterCount = function() {
        exports.filterCount = 0;
        $('.filter-badge').html('').addClass('hidden');
        $('#filters_clear_count').text('');
        $('.btn-reset-filters').addClass('hidden');
        $('#btn-saved-searches').addClass('hidden');
    };


    exports.clearManufacturersDropdown = function() {
        $('#manu-select').find('option').attr('selected', false);
        $('#manu-select').trigger('chosen:updated');
    };

    exports.adjustContainerHeight = function() {
        if (exports.isMobile) {
            return;
        }

        setTimeout(function() {
            const minDrawerHgt = 1500;
            let drawerHgt = $('.filter-drawer-inner').height();

            drawerHgt = drawerHgt < minDrawerHgt ? minDrawerHgt : drawerHgt;

            $('.main-tabs').css({
                'min-height': drawerHgt + 50 + 'px'
            });
        }, 500);
    }


    exports.populateSavedSearches = function(sd, k) {
        const setDefault = sd || false;
        const keepOpen = k || false;
        let findSavedSearches = window.is_logged_in;


        if (_data.limit_to_data) {
            findSavedSearches = false;
        }

        if (findSavedSearches) {
            exports.__requestSavedSearches(function(r) {
                exports.__drawSavedSearches(setDefault, keepOpen, r);
            });
        } else {
            $('.ss-filter').remove();
            $('#filterSavedSearches').html('');
        }
    };

    exports.__drawSavedSearches = function(setDefault, keepOpen, r) {
        let defaultView = '';

        if (r.results.length > 0) {
            const $savedSearchesContainer = $('#filterSavedSearches');

            $savedSearchesContainer.html('');

            if (!keepOpen) {
                if (r.results.length > 5) {
                    $savedSearchesContainer.addClass('show_less');
                    $('.show_more_link').removeClass('was_clicked');
                }
                $savedSearchesContainer.parent().find('.show_more_link').remove();
                $savedSearchesContainer.parent().find('.show_less_link').remove();
            }

            $.each(r.results, function(i, search) {
                exports.saved_searches[search.id] = search;

                if (typeof exports.saved_searches[search.id].filters_json === 'string') {
                    exports.saved_searches[search.id].filters_json = JSON.parse(exports.saved_searches[search.id].filters_json);
                }

                if (search.default_home_view === 1 && setDefault) {
                    defaultView = "ss-" + search.id;
                }

                if (_data.filters.hasOwnProperty('selected_search') && parseInt(_data.filters.selected_search.id) === parseInt(search.id)) {
                    const gid = guid();

                    exports.__drawSavedSearchItem($savedSearchesContainer, search, gid);

                    setTimeout(function () {
                        $('#' + gid).find('input').prop('checked', true);
                    }, 1500);

                } else {
                    exports.__drawSavedSearchItem($savedSearchesContainer, search);
                }
            });

            if (!keepOpen) {
                $savedSearchesContainer.after('' +
                    '<li class="show_more_link"><a href="javascript:void(0);">more <i class="fas fa-angle-down"></i></a></li>' +
                    '<li class="show_less_link"><a href="javascript:void(0);">less <i class="fas fa-angle-up"></i></a></li>');

                $('.show_more_link').click(function() {
                    $savedSearchesContainer.removeClass('show_less');
                    $(this).addClass('was_clicked');
                });

                $('.show_less_link').click(function() {
                    $savedSearchesContainer.addClass('show_less');
                    $('.show_more_link').removeClass('was_clicked');
                });
            }

            $savedSearchesContainer.find('.fa-star').parent().tooltip({'trigger':'hover'});
            $savedSearchesContainer.find('.fa-envelope').parent().tooltip({'trigger':'hover'});

            $('.saved-search-filter').click(function() {
                const id = $(this).data('id');

                if ($(this).is(':checked')) {
                    _data.remove_saved_search();

                    $(this).prop('checked', true);

                    _data.filters = $.extend(true, {}, exports.saved_searches[id].filters_json);

                    _data.filters.selected_search = {
                        id: id,
                        name: exports.saved_searches[id].filter_name
                    };

                    if (_data.filters.hasOwnProperty('search')) {
                        $('.tl-search__input').val(_data.filters.search);
                    } else {
                        $('.tl-search__input').val('');
                    }

                    _data.refresh(function () {
                        exports.populateFilters(_data.filters);
                        document.dispatchEvent(new CustomEvent('listings.get', {}));
                    }, true);
                } else {
                    $('.btn-reset-filters').click();
                }
            });

            $('.delete-search').click(function(e) {
                exports.__removeSavedSearch($(e.target).data('id'));
                e.stopPropagation();
            });

            if (defaultView !== '' && $('.' + defaultView).length > 0) {
                $('.' + defaultView).click();
            }
        } else {
            $('.ss-filter').remove();
            $('#filterSavedSearches').html('');
        }
    };

    exports.__drawSavedSearchItem = function($container, search, gid) {
        gid = gid ? gid : '';
        var selection_id = -1;
        if(_data.filters.hasOwnProperty('selected_search')) {
            selection_id = _data.filters.selected_search.id;
        }

        const result = '' +
            '<li class="fsearch ' + gid + '">' +
            '<label class="checkbox">' +
            '<input '+
            (search.id == selection_id?'checked':'')+
            ' type="checkbox" name="fcheck" class="saved-search-filter ss-' + search.id + '" data-id="' + search.id + '">' +
            '<i></i>' + search.filter_name + '' +
            (search.alert_schedule > 0 ? '&nbsp;<a href="javascript:void(0);" data-container="body" data-toggle="tooltip" data-placement="top" title="Sends Alerts to ' + (typeof user !== 'undefined' ? user.EMAIL : 'your email.') + '" ><span class="fas fa-envelope color-grey"></span></a>&nbsp;' : '') +
            (search.default_home_view === 1 ? '&nbsp;<a href="javascript:void(0);"  data-container="body" data-toggle="tooltip" data-placement="top" title="Displays as your Home Page"><span class="fas fa-star tl-color--secondary" ></span></a>&nbsp;' : '') +
            '</label> ' +
            '</li>';

        if (search.default_home_view === 1) {
            $container.prepend(result);
        } else {
            $container.append(result);
        }

    };

    exports.__removeSavedSearch = function(id) {
        framework.post(url('api/delete_search'), {
            _token: $('input[name="_token"]:first').val(),
            id: id
        }, function(r) {
            if (_data.filters.hasOwnProperty('selected_search')) {
                if (_data.filters.selected_search.id === id) {
                    _data.remove_saved_search();
                }
            }

            $('.ss-' + id).before('<li class="saved-search deleted-search alert alert-danger">Deleted Saved Search</li>');
            $('.ss-' + id).remove();
        });
    };


    exports.getSearches = function(sd) {
        const setDefault = sd || false;
        let defaultView = '';

        $('.saved-search').remove();
        $('.save-this-search').removeClass('hidden');
        $('.save-search-form').addClass('hidden');

        if ($('.saved-menu-message').length === 0 && !_data.limit_to_data) {
            exports.__requestSavedSearches(function(r) {
                if (r.results.length === 0) {

                    $('.modal-body').html('<div style="text-align:center; padding:20px; font-size:24px;">No Saved Filters found.</div>');

                } else if (r.results.length > 0) {

                    $.each(r.alerts, function(i, search) {
                        if (search.default_home_view === 1) {
                            defaultView = "ss-" + search.id;
                        }

                        exports.__drawSearchItem(i, search, true);
                    });

                    $.each(r.searches, function(i, search) {
                        if (search.default_home_view === 1) {
                            defaultView = "ss-" + search.id;
                        }

                        exports.__drawSearchItem(i, search);
                    });

                    $('.saved-menu').find('.fa-star').parent().tooltip({'trigger': 'hover'});
                    $('.saved-menu').find('.fa-envelope').parent().tooltip({'trigger': 'hover'});

                    let action_clicked = false;

                    $('.saved-actions').click(function() {
                        action_clicked = true;
                    });

                    $('.saved-search').click(function() {
                        if (!action_clicked) {
                            $('.modal .close').click();
                            $('.ss-' + $(this).data('id')).click();
                        }
                        action_clicked = false;
                    });

                    $('.delete-search').click(function(e) {
                        exports.__removeSearch($(e.target).data('id'));
                        e.stopPropagation();
                    });

                    if (setDefault) {
                        if (defaultView !== '' && $('.' + defaultView).length > 0) {
                            $('.' + defaultView).click();
                        }
                    }
                }
            });
        }
    };

    exports.__removeSearch = function(id) {
        framework.post(url('api/delete_search'), {
            _token: $('input[name="_token"]:first').val(),
            id: id
        }, function(r) {
            if (_data.filters.hasOwnProperty('selected_search')) {
                if (_data.filters.selected_search.id === id) {
                    _data.remove_saved_search();
                }
            }

            exports.populateSavedSearches(false, true);

            $('.sas-' + id).before('<li class="saved-search deleted-search alert alert-danger">Deleted Saved Filter</li>');
            $('.sas-' + id).remove();
        });
    };

    exports.__requestSavedSearches = function(callback) {
        framework.post(url('api/saved_searches'), {
            _token: $('input[name="_token"]:first').val()
        }, function(r) {
            callback(r);
        }, function(e, data) {
            console.debug(e, data);
        });
    };

    exports.__drawSearchItem = function(index, search, isAlert) {
        exports.saved_searches[search.id] = search;

        if (typeof exports.saved_searches[search.id].filters_json === 'string') {
            exports.saved_searches[search.id].filters_json = JSON.parse(exports.saved_searches[search.id].filters_json);
        }

        const sendsAlertsHTML = (isAlert && search.alert_schedule > 0) ? '&nbsp;<a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Sends Alerts to ' + (typeof user !== 'undefined' ? user.EMAIL : 'your email.') + '" ><i class="fas fa-envelope color-grey"></i></a>&nbsp;' : '';
        const displayAsHomePageHTML = search.default_home_view === 1 ? '&nbsp;<a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Displays as your Home Page"><i class="fas fa-star tl-color--secondary"></i></a>&nbsp;' : '';
        const dateVerbose = moment(search.created_on).fromNow();
        const dateFull = moment(search.created_on).format('MMM D, YYYY') + ' at ' + moment(search.created_on).format('hh:mm A');
        const shtml = '' +
            `<li class="saved-search sas-${search.id}" data-id="${search.id}">
                <a href="javascript:void(0)">${search.filter_name}</a>&nbsp;
                ${sendsAlertsHTML}
                ${displayAsHomePageHTML}
                <span class="saved-actions"><i class="fas fa-trash-alt delete-search"  data-id="${search.id}"></i></span>
                <p class="search-created">
                    <span class="tl-anim-btn tl-text--muted"><span class="tl-date-btn">${dateVerbose}</span><span>${dateFull}</span></span>
                </p>
            </li>`;

        if (search.default_home_view === 1) {
            $('.save-this-search').after('<li class="saved-search-header"><b>Displays as your Home Page</b></li>' + shtml);
        } else {
            if (index === 0) {
                if (isAlert) {
                    $('.saved-menu').append('<li class="saved-search-header"><b>Sends Alerts to ' + (typeof user !== 'undefined' ? user.EMAIL : 'your email.') + '</b></li>');
                } else {
                    $('.saved-menu').append('<li class="saved-search-header"><b>View Online Only</b></li>');
                }
            }
            $('.saved-menu').append(shtml);
        }
    };


    exports.getCategory = function(type, id, data) {
        if (!data) return false;
        const result = data.filter(item => item.id === id);
        return result.length > 0 ? result[0] : false;
    };


    exports.populateFilters = function(filters) {
        // If the page have no filter drawer there's no need to re-populate filters.
        if ($('#filter-drawer').length === 0) {
            return;
        }

        exports.resetFilterCount();

        const checkedCreatedFilter = _data.filters.hasOwnProperty('created_by') && is_logged_in && parseInt(_data.filters.created_by) === user.USERID;
        $('.created-filter').prop('checked', checkedCreatedFilter);

        const checkedRepliedFilter = _data.filters.hasOwnProperty('replied_listings');
        $('.replied-filter').prop('checked', checkedRepliedFilter);

        $('[data-filter-button]').addClass('hidden');
        $('#filterCategories').html('');
        $('.mfg, .brands, .models, .types, .cond, .geo').addClass('hidden');
        $('#filterOptions, #filterBrands, #filterModels, #filterTypes, #filterConditions, #filterGeography').addClass('hidden').html('');
        $('.show-more-manus').addClass('hidden');

        if (filters.hasOwnProperty('search') && filters.search !== '') {
            exports.__updateFilterButton('search');
        }

        if (filters.hasOwnProperty('user_id') && filters.user_id !== '') {
            exports.__updateFilterButton('user_id');
        }

        if (exports.isMemberViewing) {
            exports.populateShowResults(filters);
        }

        if (exports.data.core === CORE.listing) {
            exports.populateTradeTypes(filters);
        }

        exports.populateCategories(filters);
        exports.populateManufacturers(filters);

        if (filters.hasOwnProperty('manufacturer_ids') && filters.manufacturer_ids.length > 0) {
            exports.populateBrands(filters);
        }

        if (filters.hasOwnProperty('brand_ids') && filters.brand_ids.length > 0) {
            exports.populateModels(filters);
        }

        if (filters.hasOwnProperty('model_ids') && filters.model_ids.length > 0) {
            exports.populateTypes(filters);
        }

        if (filters.hasOwnProperty('categories')) {
            exports.populateConditions(filters);
        }

        if (exports.data.core === CORE.part) {
            $('#filterMessageType, #filterMessageTypeTitle').hide();
        }

        exports.populateCountries(filters);

        if (exports.isMemberViewing) {
            exports.populateCompanies(filters);
        }

        document.dispatchEvent(new CustomEvent('filter.changed', {}));
    };


    // trade types
    exports.populateTradeTypes = function(filters) {
        const hasType = filters.hasOwnProperty('type');
        const hasTargetType = filters.hasOwnProperty('target_type');

        const isActiveWTB = !hasType || parseInt(filters.type) === 1;
        const isActiveWTS = !hasType || parseInt(filters.type) === 2;
        const isActiveBulk = !hasType && !hasTargetType ||
            !hasType && parseInt(filters.target_type) === 1 ||
            parseInt(filters.type) === 2 && !hasTargetType ||
            parseInt(filters.type) === 2 && parseInt(filters.target_type) === 1;
        const isActiveItem = !hasType && !hasTargetType ||
            !hasType && parseInt(filters.target_type) !== 1 ||
            parseInt(filters.type) === 2 && !hasTargetType ||
            parseInt(filters.type) === 2 && parseInt(filters.target_type) !== 1;

        $('.wtb-filter').prop('checked', isActiveWTB);
        $('.wts-filter').prop('checked', isActiveWTS);
        $('.target-bulk-filter').prop('checked', isActiveBulk);
        $('.target-indiv-filter').prop('checked', isActiveItem);
    };


    // show results
    exports.populateShowResults = function(filters) {
        let membersOnlyChecked = true;
        let publicOnlyChecked = true;

        if (filters.hasOwnProperty('displays_to_non_members') && filters.displays_to_non_members === 0) {
            publicOnlyChecked = false;
        } else if (filters.hasOwnProperty('displays_to_non_members') && filters.displays_to_non_members === 1) {
            membersOnlyChecked = false;
        }

        $('[name="show_results"][value="members_only"]')[0].checked = membersOnlyChecked;
        $('[name="show_results"][value="public_only"]')[0].checked = publicOnlyChecked;
    };


    // categories
    exports.populateCategories = function(filters) {

        exports.__updateFilterButton('categories');

        if (filters.hasOwnProperty('categories')) {
            exports.updateFilterCount(1);
        }

        if (typeof category_tree !== 'undefined') {
            if (filters.hasOwnProperty('categories') && typeof filters.categories[0] !== 'undefined') {
                const cat1id = filters.categories[0].id;
                const cat1 = category_tree[cat1id];

                document.getElementById('filterCategories').innerHTML = exports.__drawCategory1Item({
                    id: cat1id,
                    title: cat1.title,
                    isChecked: true,
                    hasSubcategories: true
                });

                if (Object.keys(cat1.subcategories).length > 1) {
                    exports.__drawCat2Items(filters, cat1.subcategories, cat1, cat1id);
                }
            } else {
                exports.__drawCat1Items(category_tree.categories);
            }
        }
    };

    exports.__drawCat1Items = function(categories) {
        const cat1Items = [];
        const cat1ItemsHTML = [];

        categories.forEach(function(v) {
            cat1Items.push({
                id: v,
                title: category_tree[v].title,
                isChecked: false,
                hasSubcategories: false
            });
        });

        cat1Items
            .sort(function(a, b) {
                return a.title.toUpperCase().localeCompare(b.title.toUpperCase());
            })
            .forEach(function(item) {
                cat1ItemsHTML.push(exports.__drawCategory1Item(item));
            });

        document.getElementById('filterCategories').innerHTML = cat1ItemsHTML.join('');
    };

    exports.__drawCategory1Item = function(item) {
        return `<li class="cat1">
                    <label class="checkbox">
                        <input type="checkbox" name="checkbox" class="cat-1-filter" 
                               data-text="${item.title}" data-id="${item.id}" ${item.isChecked ? ' checked="true"' : ''}>
                        <i></i>${item.title}
                    </label>
                </li>
                ${item.hasSubcategories ? `<ul id="filterCategories2-in-${item.id}" class="filter-sub-options sky-form collapse in"></ul>` : ''}`;
    };

    exports.__drawCat2Items = function(filters, categories, cat1, cat1id) {
        const cat2ItemsHTML = [];

        Object.keys(categories).forEach(function(cat2id) {
            const cat2 = cat1.subcategories[cat2id];
            const c2 = exports.getCategory('cat_2_id', parseInt(cat2id), filters.categories[0].sub_categories);
            let cat2ItemHTML = exports.__drawCategory2Item({
                id: cat2id,
                title: cat2.title,
                cat1id: cat1id,
                isChecked: !!c2
            });

            if (cat2.subcategories && Object.keys(cat2.subcategories).length > 1) {
                cat2ItemHTML += `<ul id="filterCategories3-in-${cat2id}" class="filter-sub-options sky-form collapse in">`;
                cat2ItemHTML += exports.__drawCat3Items(c2, cat2.subcategories, cat2id, cat1id);
                cat2ItemHTML += `</ul>`;
            }

            cat2ItemsHTML.push(cat2ItemHTML);
        });

        document.getElementById('filterCategories2-in-' + cat1id).innerHTML = cat2ItemsHTML.join('');
    };

    exports.__drawCategory2Item = function(item) {
        return `<li class="cat2">
                    <label class="checkbox">
                        <input type="checkbox" name="checkbox" class="cat-2-filter" 
                               data-cat1id="${item.cat1id}" data-id="${item.id}" ${item.isChecked ? ' checked="true"' : ''}>
                        <i></i>${item.title}
                    </label>
                </li>`;
    };

    exports.__drawCat3Items = function(c2, categories, cat2id, cat1id) {
        const cat3Items = [];

        Object.keys(categories).forEach(function(cat3id) {
            const cat3 = categories[cat3id];

            if (cat3.title !== null && c2) {
                const c3 = exports.getCategory('cat_3_id', parseInt(cat3id), c2.sub_categories);
                cat3Items.push({
                    id: cat3id,
                    title: cat3.title,
                    cat1id: cat1id,
                    cat2id: cat2id,
                    isChecked: !!c3
                });
            }
        });

        if (cat3Items.length > 0) {
            const cat3ItemsHTML = [];
            cat3Items
                .sort(function(a, b) {
                    return a.title.toUpperCase().localeCompare(b.title.toUpperCase());
                })
                .forEach(function(item) {
                    cat3ItemsHTML.push(exports.__drawCategory3Item(item));
                });

            return cat3ItemsHTML.join('');
        }

        return '';
    };

    exports.__drawCategory3Item = function(item) {
        return `<li class="cat3">
                    <label class="checkbox">
                        <input type="checkbox" name="checkbox" class="cat-3-filter cat-3-${item.id}" 
                               data-cat1id="${item.cat1id}" data-cat2id="${item.cat2id}" data-id="${item.id}" 
                               ${item.isChecked ? ' checked="true"' : ''}>
                        <i></i>${item.title}
                    </label>
                </li>`;
    };


    // manufacturers
    exports.populateManufacturers = function(filters) {
        let filter_str = [];

        exports.__updateFilterButton('manufacturer_ids');

        if (filters.hasOwnProperty('categories')) {
            const str = _data.parseCategories(filters.categories);
            filter_str.push(str);
        }

        if (filters.hasOwnProperty('manufacturer_ids') && filters.manufacturer_ids.length > 0) {
            exports.updateFilterCount(1);

            $.each(filters.manufacturer_ids, function(i, v) {
                filters.manufacturer_ids[i] = parseInt(v);
            });
        }

        filter_str.push(...exports.__getExtraFilterStrings(_data, true));

        const manufacturersFilterString = exports.__getManufacturersFilterString(filters);
        if (manufacturersFilterString !== '') {
            filter_str.push(manufacturersFilterString);
        }

        exports.__requestFilterData({
            fields: ['manu_id', 'manufacturer_name'],
            filters: filter_str,
            group: ['manu_id'],
            sort: ['manufacturer_name:ASC']
        }, function(response) {
            // parse response and fire an event that new manus are loaded
            let manus = exports.__parseManufacturersResponse(response);

            document.dispatchEvent(new CustomEvent('filter.loadedManu', {
                detail: {
                    manus: manus
                }
            }));

            exports.__drawManufacturers(manus, filters);
        });
    };

    exports.__getManufacturersFilterString = function(filters) {
        const manufacturerFilterString = exports.__buildManufacturersFilterString(filters);

        if (!filters.hasOwnProperty('categories')) {
            if (manufacturerFilterString !== '') {
                return "(is_major:true OR " + manufacturerFilterString + ")";
            }
            return "is_major:true";
        } else {
            if (manufacturerFilterString !== '') {
                return "(is_category_manu:true OR " + manufacturerFilterString + ")";
            }
            return "is_category_manu:true";
        }
    };

    exports.__buildManufacturersFilterString = function(filters) {
        if (filters.hasOwnProperty('manufacturer_ids') && filters.manufacturer_ids.length > 0) {
            filters.manufacturer_ids = filters.manufacturer_ids.getUnique();

            let str = "manu_id:(";
            let atLeastOne = false;

            $.each(filters.manufacturer_ids, function(i, cid) {
                if (cid && cid !== '' && cid !== 'undefined' && !isNaN(cid)) {
                    str += (i === 0 ? "" : " OR ");
                    atLeastOne = true;
                    if (cid === -2) {
                        str += '"' + cid + '"';
                    } else {
                        str += cid;
                    }
                }
            });

            str += ")";

            return atLeastOne ? str : '';
        }
        return '';
    };

    exports.__getExtraFilterStrings = function(data, shouldRemoveCore) {
        const result = [];

        if (data.filters.hasOwnProperty('type')) {
            result.push("subject_typeid:" + data.filters.type);
        }

        if (data.filters.hasOwnProperty('country_codes') && data.filters.country_codes.length > 0) {
            result.push('company_country_code:(' + data.filters.country_codes.join(' OR ') + ')');
        }

            if (data.filters.hasOwnProperty('displays_to_non_members')) {
            result.push('displays_to_non_members:' + data.filters.displays_to_non_members.toString());
        }

        if (exports.data.core === CORE.listing) {
            result.push("is_maint_only:false");
            result.push("should_display_profile:true");

            if (!shouldRemoveCore) {
                result.push("core:listing");
            }
        } else if (exports.data.core === CORE.part) {
            if (!shouldRemoveCore) {
                result.push("core:part");
            }
        }

        result.push("is_hidden:false");

        return result;
    }

    exports.__parseManufacturersResponse = function(response) {
        let result = [];

        if (response.grouped.manu_id.groups.length > 0) {
            response.grouped.manu_id.groups.forEach(function(group) {
                group.doclist.docs.forEach(function(manufacturer) {
                    if (manufacturer.hasOwnProperty('manufacturer_name') && manufacturer.manu_id !== 0) {
                        result.push({
                            id:     manufacturer.manu_id,
                            name:   manufacturer.manufacturer_name,
                        });
                    }
                });
            });
        }

        return result;
    };

    exports.__drawManufacturers = function(manus, filters) {
        if (manus.length > 0) {
            const manufacturerItems = [];

            $('.mfg').removeClass('hidden');
            $('#filterOptions').html('').removeClass('hidden');
            $('.show-more-manus').removeClass('hidden');

            // adding 'Mixed Lots' at the beginning of the manufacturers list
            manufacturerItems.push(exports.__drawFilterItem({
                id: -2,
                text: 'Mixed Lots',
                class: 'mfg-filter',
                isChecked: filters.hasOwnProperty('manufacturer_ids') && filters.manufacturer_ids.indexOf(-2) !== -1
            }));

            manus.forEach(function(manufacturer) {
                if (parseInt(manufacturer.id) !== -2) {
                    manufacturerItems.push(exports.__drawFilterItem({
                        id: manufacturer.id,
                        text: manufacturer.name,
                        class: 'mfg-filter',
                        field: 'manufacturer_ids',
                        isChecked: filters.hasOwnProperty('manufacturer_ids') && filters.manufacturer_ids.indexOf(parseInt(manufacturer.id)) !== -1
                    }));
                }
            });

            document.getElementById('filterOptions').innerHTML = manufacturerItems.join('');
        }
    };


    // brands
    exports.populateBrands = function(filters) {
        let filter_str = [];

        exports.__updateFilterButton('brand_ids');

        const manufacturersFilterString = exports.__buildManufacturersFilterString(filters);
        if (manufacturersFilterString !== '') {
            filter_str.push(manufacturersFilterString);
        }

        if (filters.hasOwnProperty('categories')) {
            filter_str.push(_data.parseCategories(filters.categories));
        }

        if (filters.hasOwnProperty('brand_ids') && filters.brand_ids.length > 0) {
            exports.updateFilterCount(1);

            $.each(filters.brand_ids, function(i, v) {
                filters.brand_ids[i] = parseInt(v);
            });
        }

        filter_str.push(...exports.__getExtraFilterStrings(_data));

        exports.__requestFilterData({
            fields:['brand_id','brand_name','cat1','cat2','cat3'],
            filters: filter_str,
            group: ['brand_id'],
            sort:['brand_name:ASC']
        },function(response) {
            exports.__drawFilterItemList(response, filters, 'brand_ids', {
                field: 'brand_id',
                fieldValue: 'brand_id',
                fieldDesc: 'brand_name',
            });
        });
    };


    // series
    exports.populateModels = function(filters) {
        let filter_str = [];

        exports.__updateFilterButton('model_ids');

        const manufacturersFilterString = exports.__buildManufacturersFilterString(filters);
        if (manufacturersFilterString !== '') {
            filter_str.push(manufacturersFilterString);
        }

        filter_str.push("brand_id:(" + filters.brand_ids.join(" OR ") + ")");

        if (filters.hasOwnProperty('categories')) {
            const str = _data.parseCategories(filters.categories);
            filter_str.push(str);
        }

        if (filters.hasOwnProperty('model_ids') && filters.model_ids.length > 0) {
            exports.updateFilterCount(1);

            $.each(filters.model_ids, function(i, v) {
                filters.model_ids[i] = parseInt(v);
            });
        }

        filter_str.push(...exports.__getExtraFilterStrings(_data));

        exports.__requestFilterData({
            fields: ['model_id', 'model_name'],
            filters: filter_str,
            group: ['model_id'],
            sort: ['model_name:ASC']
        }, function(response) {
            exports.__drawFilterItemList(response, filters, 'model_ids', {
                field: 'model_id',
                fieldValue: 'model_id',
                fieldDesc: 'model_name',
            });
        });
    };


    // models
    exports.populateTypes = function(filters) {
        const filter_str = [];

        exports.__updateFilterButton('type_ids');

        const manufacturersFilterString = exports.__buildManufacturersFilterString(filters);
        if (manufacturersFilterString !== '') {
            filter_str.push(manufacturersFilterString);
        }

        filter_str.push("brand_id:(" + filters.brand_ids.join(" OR ") + ")");
        filter_str.push("model_id:(" + filters.model_ids.join(" OR ") + ")");

        if (filters.hasOwnProperty('categories')) {
            const str = _data.parseCategories(filters.categories);
            filter_str.push(str);
        }

        if (filters.hasOwnProperty('type_ids') && filters.type_ids.length > 0) {
            exports.updateFilterCount(1);

            $.each(filters.type_ids, function(i, v) {
                filters.type_ids[i] = parseInt(v);
            });
        }

        filter_str.push(...exports.__getExtraFilterStrings(_data));

        exports.__requestFilterData({
            fields: ['catalog_model_types_id', 'type_number'],
            filters: filter_str,
            group: ['catalog_model_types_id'],
            sort: ['type_number:DESC']
        }, function(response) {
            exports.__drawFilterItemList(response, filters, 'type_ids', {
                field: 'catalog_model_types_id',
                fieldValue: 'catalog_model_types_id',
                fieldDesc: 'type_number',
            });
        });
    };


    // conditions
    exports.populateConditions = function(filters) {
        const filter_str = [];

        exports.__updateFilterButton('condition_ids');

        if (filters.hasOwnProperty('categories')) {
            const str = _data.parseCategories(filters.categories);
            filter_str.push(str);
        }

        if (filters.hasOwnProperty('condition_ids') && filters.condition_ids.length > 0) {
            exports.updateFilterCount(1);

            $.each(filters.condition_ids, function(index, value) {
                filters.condition_ids[index] = parseInt(value);
            });
        }

        filter_str.push(...exports.__getExtraFilterStrings(_data));

        exports.__requestFilterData({
            fields: ['condition_id', 'condition'],
            filters: filter_str,
            group: ['condition_id'],
            sort: ['condition:ASC']
        }, function(response) {
            exports.__drawFilterItemList(response, filters, 'condition_ids', {
                field: 'condition_id',
                fieldValue: 'condition_id',
                fieldDesc: 'condition',
            });
        });
    };


    // regions
    exports.populateGeography = function(filters) {
        const filter_str = [];

        exports.__updateFilterButton('countries');

        if (filters.hasOwnProperty('categories')) {
            const str = _data.parseCategories(filters.categories);
            filter_str.push(str);
        }

        if (filters.hasOwnProperty('countries') && filters.countries.length > 0) {
            exports.updateFilterCount(1);
        }

        filter_str.push(...exports.__getExtraFilterStrings(_data, true));

        exports.__requestFilterData({
            fields: ['ships_from_country', 'region_name'],
            filters: filter_str,
            group: ['region_name'],
            sort: ['region_name:ASC']
        }, function(response) {
            exports.__drawFilterItemList(response, filters, 'countries', {
                field: 'region_name',
                fieldValue: 'region_name',
                fieldDesc: 'region_name',
            });
        });
    };


    // geography 2
    exports.populateCountries = function(filters) {
        let filter_str = '';

        exports.__updateFilterButton('country_codes');
        exports.__updateFilterButton('countries');

        if (exports.data.core !== null && exports.data.core !== '') {
            filter_str = _data.parseFilterParams(_data.filters, exports.data.core);
        } else {
            filter_str = _data.parseFilterParams(_data.filters);
        }

        if (filters.hasOwnProperty('country_codes') && filters.country_codes.length > 0) {
            exports.updateFilterCount(1);

            // delete countries from filter string for solr to get all countries for search list
            filter_str = deleteFilterLine(filter_str, 'ships_from_country_code');
        }

        if (filters.hasOwnProperty('countries') && filters.countries.length > 0) {
            exports.updateFilterCount(1);

            // delete regions from filter string for solr to get all countries for search list
            filter_str = deleteFilterLine(filter_str, 'region_name');
        }

        // delete companies from filter string for solr to get all countries for search list
        if (filters.hasOwnProperty('company_id') && filters.company_id.length > 0) {
            filter_str = deleteFilterLine(filter_str, 'companyid');
        }

        exports.__requestFilterData({
            fields: ['region_name', 'ships_from_country_code'],
            filters: filter_str,
            group: ['ships_from_country_code'],
            sort: ['ships_from_country_code:ASC'],
            search: exports.__getSearchString(),
        }, function(response) {
            exports.__drawGeographies(response, filters);
        });
    };


    // companies
    exports.populateCompanies = function(filters) {
        let filter_str = '';

        exports.__updateFilterButton('company_id');

        if (exports.data.core !== null && exports.data.core !== '') {
            filter_str = _data.parseFilterParams(filters, exports.data.core);
        } else {
            filter_str = _data.parseFilterParams(filters);
        }

        if (filters.hasOwnProperty('company_id') && filters.company_id.length > 0) {
            exports.updateFilterCount(1);

            $.each(filters.company_id, function(i, v) {
                filters.company_id[i] = parseInt(v);
            });

            // delete companyid from filter string for solr to get all companies for search list
            filter_str = deleteFilterLine(filter_str, 'companyid');
        }

        exports.__requestFilterData({
            fields: ['companyid', 'company_name'],
            filters: filter_str,
            group: ['companyid'],
            sort: ['company_name:ASC'],
            search: exports.__getSearchString(),
        }, function(response) {
            exports.__drawFilterItemList(response, filters, 'company_id', {
                field: 'companyid',
                fieldValue: 'companyid',
                fieldDesc: 'company_name',
            });
        });
    };


    exports.__drawGeographies = function (r, filters) {
        exports.geographies = [];

        if (r.grouped['ships_from_country_code'].groups.length > 0) {
            const rawItems = [];
            const itemsHTML = [];

            r.grouped['ships_from_country_code'].groups.forEach(function(group) {
                group.doclist.docs.forEach(function(item) {
                    if (item.hasOwnProperty('ships_from_country_code') && item['ships_from_country_code'] !== '') {
                        const country_code = item['ships_from_country_code'];

                        if (!item.hasOwnProperty('region_name') || !item['region_name']) {
                            return;
                        }

                        if (!exports.geographies.hasOwnProperty(item['region_name'])) {
                            exports.geographies[item['region_name']] = [];
                        }

                        exports.geographies[item['region_name']].push(country_code);

                        let data = {
                            id: country_code,
                            text: window.hasOwnProperty('countries') && window.countries.hasOwnProperty(country_code)
                                ? window.countries[country_code]
                                : country_code,
                            field: 'country_codes',
                            isChecked: filters.hasOwnProperty('country_codes') && filters['country_codes'].indexOf(country_code) !== -1
                        };

                        // add data to proper region in raw items
                        let regionIndex = rawItems.findIndex(value => {
                            return value.id === item['region_name'];
                        });

                        if (regionIndex === -1) {
                            const isRegionChecked = filters.hasOwnProperty('countries') && filters['countries'].indexOf(item['region_name']) !== -1;

                            rawItems.push({
                                id: item['region_name'],
                                text: item['region_name'],
                                field: 'countries',
                                isChecked: isRegionChecked,
                                hideChildren: !isRegionChecked,
                                children: []
                            });

                            regionIndex = rawItems.length - 1;
                        }

                        rawItems[regionIndex].children.push(data);
                    }
                });
            });

            rawItems.forEach(function(item) {
                item.children.sort(function(a, b) {
                    return a.text.toUpperCase().localeCompare(b.text.toUpperCase());
                });

                itemsHTML.push(exports.__drawFilterItem(item));
            });

            $('[data-fd-item-title="country_codes"]').removeClass('hidden');
            $('[data-fd-item-list="country_codes"]').removeClass('hidden').html(itemsHTML.join(''));
        } else {
            // hide geography-2 dropdown from filter drawer
            $('[data-fd-item-title="country_codes"]').addClass('hidden');
            $('[data-fd-item-list="country_codes"]').addClass('hidden').html('');
        }

        exports.adjustContainerHeight();
    };


    // general draw functions
    exports.__drawFilterItemList = function(r, filters, filterField, solr) {
        if (r.grouped[solr.field].groups.length > 0) {
            const rawItems = [];
            const itemsHTML = [];

            r.grouped[solr.field].groups.forEach(function(group) {
                group.doclist.docs.forEach(function(item) {
                    if (item.hasOwnProperty(solr.fieldDesc) && item[solr.fieldDesc] !== '') {
                        const v = isNaN(parseInt(item[solr.fieldValue]))
                            ? item[solr.fieldValue]
                            : parseInt(item[solr.fieldValue]);

                        let data = {
                            id: item[solr.fieldValue],
                            text: item[solr.fieldDesc],
                            field: filterField,
                            isChecked: filters.hasOwnProperty(filterField) && filters[filterField].indexOf(v) !== -1
                        };

                        if (solr.fieldDesc === 'brand_name') {
                            let categoryName = '';

                            if (typeof item.cat3 !== 'undefined' && item.cat3 !== null) {
                                categoryName = item.cat3;
                            } else if (typeof item.cat2 !== 'undefined' && item.cat2 !== null) {
                                categoryName = item.cat2;
                            } else if (typeof item.cat1 !== 'undefined' && item.cat1 !== null) {
                                categoryName = item.cat1;
                            }

                            data.extraText = '(' + categoryName + ')';
                        }

                        rawItems.push(data);
                    }
                });
            });

            if (filterField === 'brand_ids' || filterField === 'condition_ids') {
                rawItems.sort(function(a, b) {
                    return a.text.toUpperCase().localeCompare(b.text.toUpperCase());
                });
            }

            rawItems.forEach(function(item) {
                itemsHTML.push(exports.__drawFilterItem(item));
            });

            if (rawItems.length === 100) {
                itemsHTML.push('<li class="fs-italic">First 100 shown.</li>');
            }

            $('[data-fd-item-title="' + filterField + '"]').removeClass('hidden');
            $('[data-fd-item-list="' + filterField + '"]').removeClass('hidden').html(itemsHTML.join(''));

        } else {
            $('[data-fd-item-title="' + filterField + '"]').addClass('hidden');
            $('[data-fd-item-list="' + filterField + '"]').addClass('hidden').html('');
        }

        exports.adjustContainerHeight();
    };

    exports.__drawFilterItem = function(item) {
        let iconHTML = '';

        if (item.field && item.field === 'country_codes') {
            iconHTML += `<span class="flag flag-${item.id}" style="margin-right: 7px;"></span>`;
        }

        let result = `<li>
                    <label class="checkbox">
                        <input type="checkbox" name="checkbox" 
                               ${item.class ? 'class="' + item.class + '"' : ''} 
                               data-id="${item.id}" 
                               data-name="${item.text}" 
                               ${item.isChecked ? ' checked="true"' : ''} 
                               ${item.field ? ' data-filter-toggle="' + item.field + '"' : ''}>
                        <i></i>${iconHTML}${item.text}
                        ${item.extraText ? '<small>' + item.extraText + '</small>' : ''}
                    </label>`;

        if (item.children && item.children.length > 0) {
            result += `<ul class="filter-sub-options ${item.hideChildren ? 'hidden' : ''}">`;
            item.children.forEach(subItem => {
                result += exports.__drawFilterItem(subItem);
            })
            result += '</ul>';
        }

        result += '</li>';

        return result;
    };


    exports.__requestFilterData = function(extraOptions, callback) {
        let options = {
            search: '*:*',
            start: 0,
            rows: 100,
            ...extraOptions
        };

        let query = Solr.Model.searchRaw(options.search);
        if(options.filters) {
            options.filters.forEach((str)=>{
                query.whereRaw(str);
            });
        }
        if(options.hasOwnProperty('sort')) {
            options.sort.forEach((sortString)=>{
                let sort = sortString.split(':');
                query.orderBy(sort[0],sort[1]);
            });
        }
        if(options.hasOwnProperty('group')) {
            options.group.forEach((groupBy)=>{
                query.groupBy(groupBy);
            });
        }
        if(options.hasOwnProperty('fields')) {
            query.select(options.fields);
        }

        query.get(options.rows,Math.ceil(options.start/options.rows) + 1)
            .then(
                (response) => {
                    if (callback && typeof callback === "function") {
                        callback(response);
                    }
                },
                (e) => {
                    console.error('Filter Drawer error:', e);
                }
            );

        /*
        framework.get(url("api/search/listings"), {
            search: '*:*',
            start: 0,
            rows: 100,
            ...extraOptions
        }, function(response) {
            if (callback && typeof callback === "function") {
                callback(response);
            }
        }, function(e, data) {
            console.debug(e, data);
        }, {
            xhrFields: {
                withCredentials: true
            }
        });

         */
    };


    exports.__getSearchString = function() {
        let search = '*:*';

        if (_data.filters.hasOwnProperty('search') && _data.filters.search === '*') {
            return search;
        }

        if (_data.filters.hasOwnProperty('search') && _data.filters.search && _data.filters.search !== '') {
            let qpart = _data.filters.search.replace(/[^A-Za-z0-9]/gi, '');
            let qorq = "(*" + qpart + "* OR " + qpart + ")";
            search = '(part_clean:' + qorq + '^10 OR description:' + qorq + '^3 )';
            let words = _data.filters.search.split(' ');
            let search_str = "(";
            if (words) {
                words.forEach((w, i) => {
                    search_str += (i === 0 ? "" : " AND ") + " combinedSearch:" + w;
                })
            }
            search += ' OR ' + search_str + ')^1.1';
        }

        return search;
    };


    exports.chooseCat1 = function(id, cat_name) {
        const cat2s = Object.keys(window.category_tree[id].subcategories);

        _data.filters.categories = [];
        exports.__removeFilters(['manufacturer_ids','brand_ids','model_ids','type_ids'], false);

        _data.filters.categories.push({
            "id": id,
            "name": cat_name,
            "type": "cat_1_id",
            "sub_categories": []
        });

        if (cat2s.length === 1) {
            _data.filters.categories[0].sub_categories.push({
                "id": cat2s[0],
                "type": "cat_2_id",
                "sub_categories": []
            });
        }

        const event = new CustomEvent('filter.cat1.changed', {
            detail: {
                id: id,
                name: cat_name,
            }
        });
        document.dispatchEvent(event);
    };

    return exports;
})();
