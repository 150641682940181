import 'slick-carousel';
import {slugify} from "../utils";

export const ManuPlates = (function() {
    const ACTIVE_ITEM_CLASS = 'manu-plate--active';

    const exports = {
        category:   false,
        $container: null,

        debugMode:      false,
    };

    function drawManusPlates(manus) {
        if (exports.debugMode) {
            console.log('drawing new manus:', manus);
        }

        destroyCarousel();
        exports.$container[0].innerHTML = '';

        manus = manus.filter(manu => parseInt(manu.id) !== -2);

        if (manus.length === 0) {
            // hide the section
            exports.$container.parents('#manu_plates_wrapper').slideUp();
        } else {
            manus.forEach(manu => {
                exports.$container.append(getManuHTML(manu.id, manu.name));
            });

            exports.$container.parents('#manu_plates_wrapper').slideDown();
            initCarousel();
        }
    }

    function showLoading() {
        destroyCarousel();
        exports.$container.html('');

        for (let i = 0; i < 10; i++) {
            exports.$container.append('<div class="manu-plate__wrapper"><div class="manu-plate manu-plate--loading"></div></div>');
        }
    }

    function getManuHTML(id, name) {
        return '' +
            '<div class="manu-plate__wrapper">' +
                '<button data-id="' + id + '" data-text="' + name + '" class="manu-plate">' +
                    '<span class="manu-plate__title">' + name + '</span>' +
                '</button>' +
            '</div>';
    }

    function drawViewAllButton() {
        exports.$container.append('' +
            '<div class="manu-plate__wrapper">' +
                '<a href="/p/m" class="manu-plate manu-plate--view-all">' +
                    '<span class="manu-plate__title"><i class="fas fa-arrow-right"></i><span class="">View All</span></span>' +
            '   </a>' +
            '</div>');
    }

    function updateSectionTitle() {
        let text = '';

        if (_data.hasCategoryFilter()) {
            const catId = _data.filters.categories[0].id;
            const title = window.category_tree && window.category_tree.hasOwnProperty(catId)
                ? window.category_tree[catId].title
                : '';

            text = title ? 'for ' + title : '';
        }

        $('#manu_plates_title_end').text(text);

        if (exports.debugMode) {
            console.log('updated title', text);
        }
    }

    function highlightActiveManus() {
        // remove active class from all items
        $('.' + ACTIVE_ITEM_CLASS).removeClass(ACTIVE_ITEM_CLASS);

        // add active class to selected items
        if (_data.filters.hasOwnProperty('manufacturer_ids') && _data.filters.manufacturer_ids.length > 0) {
            _data.filters.manufacturer_ids.forEach(id => {
                $('.manu-plate[data-id=' + id + ']').addClass(ACTIVE_ITEM_CLASS);
            });
        }
    }

    function initCarousel() {
        exports.$container.slick({
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            centerMode: false,
            mobileFirst: true,
            prevArrow: '<button class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
            nextArrow: '<button class="slick-next"><span class="fa fa-chevron-right"></span></button>',
            responsive: [{
                breakpoint: 1900,
                settings: {
                    slidesToShow: 10,
                    slidesToScroll: 8,
                    variableWidth: true
                }
            },{
                breakpoint: 1700,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                }
            },{
                breakpoint: 1400,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,
                }
            },{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                }
            },{
                breakpoint: 767,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },{
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },{
                breakpoint: 450,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },]
        });

        if (exports.debugMode) {
            console.log('initialized carousel');
        }
    }

    function destroyCarousel() {
        if (exports.$container.hasClass('slick-initialized')) {
            exports.$container.slick('unslick');

            if (exports.debugMode) {
                console.log('destroyed carousel');
            }
        }
    }

    function initHandlers() {
        $(document).on('click', 'button.manu-plate', function() {
            const $me = $(this);

            // making item active faster, then dispatched event
            $me.addClass(ACTIVE_ITEM_CLASS);

            //const path = docPath.page + '?category_plate=' + slugify($me.data('text'));
            window.Analytics.trackInGA('manu_plate_clicked',{
                'manu_plate': slugify($me.data('text'))
            });
            //tradeloop.doVtPgHit(path);

            _data.remove_saved_search();
            document.dispatchEvent(new CustomEvent('filter.changeManu', {
                detail: {
                    id:     $me.data('id'),
                    text:   $me.data('text')
                }
            }));
            document.dispatchEvent(new CustomEvent('filter.refresh'));

            // scrollToElement($('#category_plates'), 300);
        });


        document.addEventListener('filter.changed', event => {
            // can be 1st time page load,
            // can be minor change like condition,
            // can be major change like category
            if (exports.debugMode) {
                console.log('filters changed', _data.filters);
            }

            updateSectionTitle();
            highlightActiveManus();
        });


        document.addEventListener('filter.cat1.changed', event => {
            if (exports.debugMode) {
                console.log('cat 1 changed', event.detail);
            }

            showLoading();
        });


        document.addEventListener('filter.loadedManu', event => {
            if (exports.debugMode) {
                console.log('caught new manus:', event.detail.manus);
            }

            let newCategory = _data.hasCategoryFilter() ? _data.filters.categories[0].id : null;

            // if category 1 has changed
            if (exports.category !== newCategory) {
                exports.category = newCategory;

                if (exports.debugMode) {
                    console.log('new category:', exports.category);
                }

                drawManusPlates(event.detail.manus);
                highlightActiveManus();
            }
        });
    }

    exports.init = function() {
        exports.$container = $('#manu_plates');

        initHandlers();
    }


    return exports;
})();