import {HtmlSanitizer} from '../../vendor/HtmlSanitizer';
import {url} from "../utils";


export const Search = (function() {
    const SEARCH_BREAKPOINT = 767;
    const SEARCH_FILTERED = 'SEARCH_FILTERED';
    const SEARCH_ALL = 'SEARCH_ALL';
    const SEARCH_TYPE = {
        itemSingle: 'item-single',
        inventory: 'inventory'
    };

    const exports = {};

    exports.data = {
        autocomplete: true,
        category_selector: false,
        category_selector2: false,
        container: null,
        filter_label: "Search this page",
        search_all_label: "Search all",
        redirect_link: "/listings",
        onFocus: null,
        onFocusOut: null,
        onSearchButtonClick: null,
        type: null
    };

    exports.state = {
        $searchDropdown: null,
        dropdownValue: null,
        dropdownText: null,
        submitLocked: false,
    };

    function lockSubmit() {
        exports.state.submitLocked = true;

        setTimeout(function() {
            exports.state.submitLocked = false;
        }, 1000);
    }

    function isLockedSubmit() {
        return exports.state.submitLocked;
    }

    function __updateBoxLayout(value) {
        if (value !== "") {
            $('.tl-search__clear').show();
            if (Search.data.autocomplete) {
                _data.autosuggest(value);
            }
        } else {
            $('.tl-search__clear').hide();
            if (Search.data.autocomplete) {
                $('.autocomplete-box').hide();
            }
        }
    }

    function __initControls() {
        $('.tl-search__input')
            .focus(function() {
                const val = $(this).val().trim();

                __updateBoxLayout(val);

                if (typeof Search.data.onFocus === 'function') {
                    Search.data.onFocus();
                }

            })
            .focusout(function () {
                if (Search.data.autocomplete) {
                    $('.autocomplete-box').fadeOut();
                }

                if (typeof Search.data.onFocusOut === 'function') {
                    Search.data.onFocusOut();
                }
            })
            .keyup(function (e) {
                const code = (e.keyCode ? e.keyCode : e.which);
                const val = $(this).val().trim();

                __updateBoxLayout(val);

                if (code === 13) {
                    $('.tl-search__button:first').click();
                    $('.tl-search__input:first').focusout();
                    return false;
                }
            });

        $('.tl-search__clear').click(function () {
            $('.tl-search__input').val('').focus();

            $(this).hide();

            if (_data.filters.hasOwnProperty('search')) {
                delete _data.filters.search;
                _data.filters.show_parts = 0;
            }

            _data.remove_saved_search();
            _data.rewrite_url = true;

            _data.refresh(function() {
                _data.dispatchPopulateFiltersEvent();
                setTimeout(()=>{
                    document.dispatchEvent(new CustomEvent('listings.get', {}));
                },100)

            }, true);
        });

        $('.tl-search__button').click(function(event) {
            if (isLockedSubmit()) {
                return false;
            }

            lockSubmit();

            event.preventDefault();

            if (typeof Search.data.onSearchButtonClick === 'function') {
                Search.data.onSearchButtonClick();
            }

            const val = HtmlSanitizer.SanitizeHtml($('.tl-search__input').val());

            _data.remove_saved_search();

            if (val !== "") {
                if (Search.data.category_selector && !Search.isMobile()) {
                    const search_all = $('[name="search_type"]').val();

                    if (search_all === "1") {
                        window.location = Search.data.redirect_link + '/?sp=1&f=1&k=' + val;
                    } else {
                        _data.filters.search = val;
                        _data.filters.search_trades = false;
                    }
                } else if (Search.data.category_selector2 && !Search.isMobile()) {
                    const cat1_id = $('[name="search_cat1"]').val();

                    if (cat1_id === SEARCH_ALL || cat1_id === null) {
                        _data.filters = {
                            page: 1
                        };
                    } else if (cat1_id !== SEARCH_FILTERED) {
                        document.dispatchEvent(new CustomEvent('filter.changeCat1', {
                            detail: {
                                id:     cat1_id,
                                text:   window.category_tree[cat1_id].title
                            }
                        }));
                    }

                    _data.filters.search = val;
                } else if (Search.data.type === SEARCH_TYPE.itemSingle) {

                    window.location = Search.data.redirect_link + '/?sp=1&f=1&k=' + val;

                }else if (Search.data.type === SEARCH_TYPE.inventory) {

                    window.location = Search.data.redirect_link + '/?k=' + val;

                } else {
                    _data.filters.search = val;
                    _data.filters.search_trades = false;
                }

                _data.filters.show_parts = 1;

                _data.refresh(function() {
                    if (document.getElementById('trades_box_container') !== null) {
                        document.getElementById('trades_box_container').scrollIntoView();
                    }
                    _data.dispatchPopulateFiltersEvent();
                    document.dispatchEvent(new CustomEvent('listings.get', {}));
                });

                const insert_searchbox = window.is_logged_in ? "public" : "www";

                framework.post(url('api/log_search'), {
                    _token: $('input[name="_token"]:first').val(),
                    'search_term': _data.filters.search,
                    'searchbox': insert_searchbox
                });

            } else {
                if (_data.filters.hasOwnProperty('search')) {
                    delete _data.filters.search;
                    _data.filters.show_parts = 0;
                }

                _data.refresh(function() {
                    _data.dispatchPopulateFiltersEvent();
                    document.dispatchEvent(new CustomEvent('listings.get', {}));
                });
            }
        });
    }

    exports.init = function(options, callback) {
        if (!options.container || options.container.length < 1) {
            return;
        }

        Search.data = Object.assign({}, Search.data, options);
        Search.draw();

        if (Search.data.category_selector2) {
            document.addEventListener('filter.changed', event => {
                const filter_fields = ['categories', 'manufacturer_ids', 'brand_ids', 'model_ids', 'type_ids', 'condition_ids', 'countries'];

                let filtered = filter_fields.some(value => {
                    return _data.filters.hasOwnProperty(value);
                });

                if (filtered) {
                    $('option[value="SEARCH_FILTERED"]').prop('disabled', false);
                    Search.state.$searchDropdown.val(SEARCH_FILTERED).trigger('change');
                } else {
                    $('option[value="SEARCH_FILTERED"]').prop('disabled', true);
                    Search.state.$searchDropdown.val(SEARCH_ALL).trigger('change');
                }
            });
        }

        if (typeof callback == 'function') {
            callback();
        }
    };

    exports.draw = function() {
        let value = _data.filters.hasOwnProperty('search') ? _data.filters.search : '';
        $('.tl-search__input').val(value);

        if (Search.data.category_selector) {
            $('.tl-search').addClass('tl-search--dropdown');

            $('.tl-search__dropdown')
                .html('' +
                    '<span class="tl-search__dropdown-facade">' +
                    '   <span class="tl-search__dropdown-text">' + Search.data.filter_label + '</span>' +
                    '   <i class="fas fa-caret-down"></i>' +
                    '</span>' +
                    '<select name="search_type" class="tl-search__dropdown-select">' +
                    '   <option value="0" checked>' + Search.data.filter_label + '</option>' +
                    '   <option value="1">' + Search.data.search_all_label + '</option>' +
                    '</select>')
                .show();

            $('[name=search_type]').change(event => {
                const $me = $(event.target);
                const val = $me.find('option[value=' + $me.val() + ']').text();
                $me.prev('span').find('.tl-search__dropdown-text').text(val);
                $me.parents('.tl-search').find('input[type=search]').focus();
            });
        }

        if (Search.data.category_selector2) {
            let options = [];

            $('.tl-search').addClass('tl-search--dropdown');

            options.push('<option value="SEARCH_FILTERED" disabled>Search Filtered Results</option>');
            options.push('<option value="SEARCH_ALL">Search All</option>');
            options.push('<option disabled>──────────</option>');

            if (typeof category_tree !== 'undefined') {
                let categories = [];

                category_tree.categories.forEach(function(v) {
                    categories.push({
                        id: v,
                        title: category_tree[v].title
                    });
                });

                categories.sort((a, b) => {
                    return a.title.localeCompare(b.title);
                });

                categories.forEach(function(v) {
                    options.push('<option value="' + v.id + '">' + v.title + '</option>');
                });
            }

            $('.tl-search__dropdown')
                .html('' +
                    '<span class="tl-search__dropdown-facade">' +
                    '   <span class="tl-search__dropdown-text">Search All</span>' +
                    '   <i class="fas fa-caret-down"></i>' +
                    '</span>' +
                    '<select name="search_cat1" class="tl-search__dropdown-select">' + options.join('') + '</select>')
                .show();

            Search.state.$searchDropdown = $('[name=search_cat1]');

            Search.state.$searchDropdown.change(event => {
                Search.state.dropdownValue = Search.state.$searchDropdown.val();
                Search.state.dropdownText = Search.state.$searchDropdown.find('option[value=' + Search.state.dropdownValue + ']').text();
                $('.tl-search__dropdown-text').text(Search.state.dropdownText);
            });
        }

        if (Search.data.autocomplete) {
            $('.tl-search').append('<ul class="tl-search__autocomplete autocomplete-box"></ul>');
        }

        __initControls();
    };

    exports.isMobile = function() {
        return framework.screen().width < SEARCH_BREAKPOINT;
    };

    return exports;
})();
