import {url} from "../utils";

class Query {
    constructor(model) {
        this._model = model;
    }

    where(field, value) {
        if(field instanceof Expression) {
            this._model.filter.push(field.build());
        } else {
            this._model.filter.push(field + ':' + value);
        }
        return this;
    }

    whereRaw(str) {
        this._model.filter.push(str);
        return this;
    }

    orderBy(field, direction) {
        this._model.sort.push(field + ':' + direction);
        return this;
    }

    groupBy(field) {
        this._model.group.push(field);
        return this;
    }

    search(searchString) {
        this._model.searchString = searchString;
        return this;
    }

    searchRaw(str) {
        this._model.rawSearch = str;
        return this;
    }

    get(rows, page) {
        return this._model.get(rows,  page);
    }

    select(fields) {
        this._model.fields = this._model.fields.concat(fields);
        return this;
    }

    facet(json) {
        this._model.facet = json;
        return this;
    }
}

class Expression {
    constructor(field, value) {
        this._field = field;
        this._value = value;
        this.operation = 'AND';
        this.score = null;
    }

    static make(field, value) {
        return new this(field, value);
    }

    withScore(s){
        if (isNaN(s)) {
            return this;
        }
        this.score = s;
        return this;
    }

    setOperation(o) {
        if (!['OR','AND'].includes(o)) {
            return this;
        }
        this.operation = o;
        return this;
    }

    build(){
        return this._field + ':(' + this._value + ')^' + this.score;
    }

}

class AnyOf extends Expression {
    constructor(field, values) {
        super(field, values);
    }
    build(){
        if (this.score !== null) {
            return this._field + ':(' + this._value.join(' OR ') + ')^' + this.score;
        } else {
            return this._field + ':(' + this._value.join(' OR ') + ')';
        }

    }
}

class AllOf extends Expression {
    constructor(field, values) {
        super(field, values);
    }
    build(){
        if (this.score !== null) {
            return this._field + ':(' + this._value.join(' AND ') + ')^' + this.score;
        } else {
            return this._field + ':(' + this._value.join(' AND ') + ')';
        }

    }
}

class BeginsWith extends Expression{
    constructor(field, value) {
        super(field, value);
        this._type = 'b';
    }

    build(){
        if (this.score !== null) {
            return this._field + ':'+this._value + '*^' + this.score;
        } else {
            return this._field + ':'+this._value + '*';
        }

    }
}
class EndsWith extends Expression{
    constructor(field, value) {
        super(field, value);
        this._type = 'e';
    }
    build(){
        if (this.score !== null) {
            return this._field + ':*' + this._value + '^' + this.score;
        } else {
            return this._field + ':*' + this._value + '';
        }

    }
}
class IsExactly extends Expression{
    constructor(field, value) {
        super(field, value);
        this._type = 'exact';
    }

    build(){
        if (this.score !== null) {
            return this._field + ':"' + this._value + '"^' + this.score;
        } else {
            return this._field + ':"' + this._value + '"';
        }

    }
}
class Contains extends Expression{
    constructor(field, value) {
        super(field, value);
        this._type = 'contains';
    }

    build(){
        if (this.score !== null) {
            return this._field + ':*' + this._value + '*^' + this.score;
        } else {
            return this._field + ':*' + this._value + '*';
        }

    }
}

class InRange extends Expression{
    constructor(field, value) {
        super(field, value);
        if(typeof value === 'object') {
            this._from = value.from;
            this._to = value.to;
        } else {
            this._from = '*';
            this._to = '*';
        }

        this._type = 'range';
    }

    build(){
        if (this.score !== null) {
            return this._field + ':[' + this._from + ' TO ' +
                this._to +
                + ']^' + this.score;
        } else {
            return this._field + ':[' + this._from + ' TO ' +
                this._to +
                + ']';
        }

    }
}

class GreaterThan extends Range {
    constructor(field, value) {
        super(field, value);
        this._from = value;
        this._to = '*';
        this._type = 'range';
    }
}

class LessThan extends Range {
    constructor(field, value) {
        super(field, value);
        this._from = '*';
        this._to = value;
        this._type = 'range';
    }
}


class SearchString{
    constructor() {
        this._filters = [];
        this.operation = 'AND';
        this._str = '';
    }

    setOperation(o) {
        if (!['OR','AND'].includes(o)) {
            return this;
        }
        this.operation = o;
        return this;
    }

    static where(expr) {
        var m = new this();
        m._filters.push(expr);
        return m;
    }

    andIf(expr) {
        expr.setOperation('AND');
        this._filters.push(expr);
        return this;
    }

    orIf(expr) {
        expr.setOperation('OR');
        this._filters.push(expr);
        return this;
    }

    build(){
        if (!this._filters || this._filters.length === 0) {
            return '*:*';
        }
        this._filters.forEach((f, i)=>{
            if (f.operation === 'AND' && i !== 0) {
                this._str += ' AND ';
            } else if (f.operation === 'OR' && i !== 0) {
                this._str += ' OR ';
            }
            if (f instanceof SearchString) {
                this._str += '(' + f.build() + ')';
            } else {
                this._str += f.build();
            }
        });
        return this._str;
    }

}

class Model {
    constructor() {
        this.filter = [];
        this.sort = [];
        this.group = [];
        this.fields = [];
        this.facet = {};
        this.searchString = null;
        this.rawSearch = '*:*';
        this.pagination = {
            current_page: 1,
            rows_per_page: 100
        }
    }

    static select(fields) {
        var m = new this();
        var q = new Query(m);
        q.select(fields);
        return q;
    }

    static search(searchString) {
        var m = new this();
        var q = new Query(m);
        q.search(searchString);
        return q;
    }

    static searchRaw(str) {
        var m = new this();
        var q = new Query(m);
        q.searchRaw(str);
        return q;
    }


    static where(field, value){
        var m = new this();
        var q = new Query(m);
        q.where(field, value);
        return q;
    }
    static whereRaw(str){
        var m = new this();
        var q = new Query(m);
        q.whereRaw(str);
        return q;
    }

    static orderBy(field, direction) {
        var m = new this();
        var q = new Query(m);
        q.orderBy(field, direction);
        return q;
    }

    static groupBy(field) {
        var m = new this();
        var q = new Query(m);
        q.groupBy(field);
        return q;
    }

    static fetch(rows, page) {
        var m = new this();
        return m.get(rows, page);
    }

    _generateParams(rows, page){
        var pg = page || 1;
        if(this.rawSearch === '*:*') {
            this.rawSearch =  this.searchString === null?'*:*':this.searchString.build();
        }

        var p = {
            start: (pg - 1) * rows,
            rows: rows,
            search: this.rawSearch
        };

        if (this.group.length > 0) {
            p['group'] = this.group;
        }

        if (this.filter.length > 0) {
            p['filters'] = this.filter;
        }

        if (this.sort.length > 0) {
            p['sort'] = this.sort;
        }

        if (this.fields.length > 0) {
            p['fields'] = this.fields;
        }

        if (this.facet && Object.keys(this.facet).length !== 0) {
            p['facetjson'] = JSON.stringify(this.facet);
        }

        p['_token'] = $('input[name="_token"]:first').val();

        p['tlpxtoken'] = window.tlpxtoken;

        p['x-sender'] = 'js';

        return p;
    }

    get(rows, page) {
        return new Promise((resolve, reject)=>{
            let params = this._generateParams(rows,page);
            framework.get(url("api/search/listings"),
                params,
            (response)=>{
                resolve(response)
            },(error)=>{
                reject(error);
            });

        });
    }
}

export {
    Query,
    Expression,
    BeginsWith,
    EndsWith,
    Contains,
    IsExactly,
    SearchString,
    Model,
    AnyOf,
    AllOf
}