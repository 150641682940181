import 'slick-carousel';
import {
    scrollToElement,
    slugify
} from "../utils";

export const CategoryPlates = (function() {
    const exports = {};

    exports.$container = null;

    function getCategoryHTML(id, item) {
        return '' +
            '<div class="category-plate__wrapper">' +
                '<button data-id="' + id + '" data-text="' + item.title + '" class="category-plate">' +
                    '<span class="category-plate__image"><img src="' + item.image + '" class="category-plate__thumb" alt="Wholesale ' + item.title + '" width="60" height="60"></span>' +
                    '<span class="category-plate__title">' + item.title + '</span>' +
                '</button>' +
            '</div>';
    }

    function drawViewAllButton() {
        exports.$container.append('' +
            '<div class="category-plate__wrapper">' +
                '<a href="/p" class="category-plate category-plate--view-all">' +
                    '<span class="category-plate__image"><i class="fas fa-arrow-right"></i><span class="">View All</span></span>' +
            '   </a>' +
            '</div>');
    }

    function initCategoryPlates() {
        exports.$container.slick({
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            centerMode: false,
            mobileFirst: true,
            prevArrow: '<button class="slick-prev"><span class="fa fa-chevron-left"></span></button>',
            nextArrow: '<button class="slick-next"><span class="fa fa-chevron-right"></span></button>',
            responsive: [{
                breakpoint: 1900,
                settings: {
                    slidesToShow: 12,
                    slidesToScroll: 12,
                    variableWidth: true
                }
            },{
                breakpoint: 1700,
                settings: {
                    slidesToShow: 12,
                    slidesToScroll: 12,
                }
            },{
                breakpoint: 1400,
                settings: {
                    slidesToShow: 10,
                    slidesToScroll: 10,
                }
            },{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                }
            },{
                breakpoint: 700,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                }
            },{
                breakpoint: 600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },{
                breakpoint: 450,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },{
                breakpoint: 350,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }]
        });

        $(document).on('click', 'button.category-plate', function() {
            const $me = $(this);

            // making item active faster, then dispatched event
            $me.addClass('category-plate--active');

            //const path = docPath.page + '?category_plate=' + slugify($me.data('text'));
            window.Analytics.trackInGA('category_plate_clicked',{
                'category_plate': slugify($me.data('text'))
            });
            //tradeloop.doVtPgHit(path);

            _data.remove_saved_search();
            document.dispatchEvent(new CustomEvent('filter.changeCat1', {
                detail: {
                    id:     $me.data('id'),
                    text:   $me.data('text')
                }
            }));
            document.dispatchEvent(new CustomEvent('filter.refresh'));

            scrollToElement($('#category_plates_bottom'), 300);
        });

        document.addEventListener('filter.changed', event => {
            $('.category-plate--active').removeClass('category-plate--active');

            if (_data.hasCategoryFilter()) {
                $('.category-plate[data-id=' + _data.filters.categories[0].id + ']').addClass('category-plate--active');
            }
        });

        document.addEventListener('filter.cat1.changed', event => {
            $('.category-plate').removeClass('category-plate--active');

            if (!!event.detail) {
                $('.category-plate[data-id=' + event.detail.id + ']').addClass('category-plate--active');
            }
        });
    }

    exports.init = function() {
        exports.$container = $('#category_plates');

        if (typeof category_tree !== 'undefined') {
            exports.$container[0].innerHTML = '';

            category_tree.categories.forEach(function(v) {
                exports.$container.append(getCategoryHTML(v, category_tree[v]));
            });

            drawViewAllButton();
            initCategoryPlates();
        }
    }

    return exports;
})();